<template lang="html">
  <div class="watch-box flex fj-cent fa-cent fd-col">
      <div class="cadran"></div>
      <div class="second"></div>
      <div class="minute"></div>
      <div class="hour"></div>
      <div class="top"></div>
      <div class="time"><p class="time-string">{{this.hours +" " + this.minutes + " " + this.seconds}}</p></div>
  </div>
</template>

<script>
const root = document.querySelector(":root");

export default {
  name: "ynckWatch",
  data() {
    return {
      hours: "00",
      minutes: "00",
      seconds: "00",
      milli: "00",
      timeRunning: true,
    }
  },
  computed: {
    currentTime(){
    return `${this.hours} ${this.minutes} ${this.seconds}`
    }

  },
  methods: {
    updateClock(){
      let currentTime = new Date();

      this.hours = currentTime.getHours()
      this.minutes = currentTime.getMinutes()
      this.seconds = currentTime.getSeconds()
      this.milli = currentTime.getMilliseconds()

      this.seconds < 10 ? this.seconds = "0" + this.seconds : this.seconds = this.seconds

      this.minutes < 10 ? this.minutes = "0" + this.minutes : this.minutes = this.minutes

      this.hours < 10 ? this.hours = "0" + this.hours : this.hours = this.hours

       let degHour = this.hours%12 * 360 / 12 + this.minutes*360/720 + this.seconds * 360 / (720* 60)
       root.style.setProperty('--deg-hour', degHour.toString()+"deg")

       let degMin = this.minutes * 360 / 60 + this.seconds * 360 / 3600
       root.style.setProperty('--deg-minute', degMin.toString()+"deg")

       let degSec = this.seconds * 360 / 60 + this.milli * 360 / 60000
       root.style.setProperty('--deg-second', degSec.toString()+"deg")

       if(this.$route.path=="/"){
         setTimeout(() => this.updateClock(), 80)
       }
    },
  },
  mounted(){
    if(this.timeRunning == true){
      this.updateClock()
    }
  },
  created(){
  },
}
</script>

<style lang="css">

:root{
  --hour-1: var(--color-accent);
  --hour-2: var(--color-accent-00);
  --minute-1: var(--color-sec);
  --minute-2: var(--color-sec-00);
  --second-1: var(--color-prim);
  --second-2: var(--color-prim-00);

	--circle-size : 60vw;
  --circle-size-max: 400px;
  --circle-size-min: 240px;

	--deg-hour:  ;
	--deg-minute: ;
	--deg-second: ;
  --watch-box-padding: 40px 0;

}

.watch-box{
  position: absolute;
  width: 100%;
  height: 100%;
}

.cadran {
	transform: scale(1.02);
	border-radius: 50%;
}

.top,
.hour,
.minute,
.second{
  height: var(--circle-size);
  max-height: var(--circle-size-max);
  min-height: var(--circle-size-min);
  max-width: var(--circle-size-max);
  min-width: var(--circle-size-min);
	width: var(--circle-size);
	border-radius: 50%;
	position: absolute;
  background-color: var(--color-darkest);
  transition: none;
}
.cadran {
	height: var(--circle-size);
  max-height: var(--circle-size-max);
  min-height: var(--circle-size-min);
  max-width: var(--circle-size-max);
  min-width: var(--circle-size-min);
	width: var(--circle-size);
	border-radius: 50%;
	position: absolute;
  background-color: var(--color-darkest);
  box-shadow: 0 4px 64px 0px var(--color-dark);
}
.cadran{
  transition: background-color var(--lin-norm);
}
.--mode-light .cadran,
.--mode-light .top{
  background-color: var(--color-darker);
}
.--mode-light .cadran{
  box-shadow: 0 4px 64px -8px var(--color-darker);
}

.hour{
  background: conic-gradient(
	var(--hour-2) 96%,
	var(--hour-1) 100%
	);
	transform: rotate(var(--deg-hour));
	mix-blend-mode: hard-light;
  border: 1px solid var(--over-bg);
}
.--mode-light.--prim-bright .watch-box .hour{
  --color-prim: var(--color-prim-dark);
}
.--mode-light .watch-box .hour{
  border: 1px solid var(--color-prim);
}
.minute {
  background: conic-gradient(
	var(--minute-2)  87.5%,
	var(--minute-1) 100%
	);
	mix-blend-mode: hard-light;
	transform: rotate( var(--deg-minute)) ;
}

.second {
	background: conic-gradient(
    var(--second-2) 75%,
    var(--second-1) 100%
	);
	transform: scale(1) rotate(var(--deg-second)) ;
}

.top {
	transform: scale(.2);
	border-radius: 50%;
  background-color: var(--bg-strongest);
}
.time-string{
  position: relative;
  z-index: 10;
  bottom: 0;
  width: min-content;
  line-height: 1.4;
  font-size: 1rem;
  color: var(--color-lightest);
  mix-blend-mode: hard-light;
}
@media screen and (max-width: 680px){
  /* 680px because the circle starts to shrink at this size */
  .time-string{
    /* transform: translate(-50%,0); */
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 320px){
  .time-string{
    font-size: 0.7rem;
  }
}
</style>

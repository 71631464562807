<template>
  <section class="page home">
    <!-- <div class="container-fix"> -->
      <ynckWatch/>
    <!-- </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import ynckWatch from "@/components/ynckWatch.vue"
import menuBar from "@/components/menuBar.vue"

export default {
  name: 'home',
  components: {
    ynckWatch,
  }
}
</script>

<style>

</style>
